import { all, takeLatest } from "redux-saga/effects";
import {
  UPLOAD_FILE,
  UPLOAD_MULTIPLE_FILE,
  REMOVE_FILE,
} from "../actions/file.actions.type";
import httpClient from "../services/http.client";
import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* uploadFile({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/file/upload`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* uploadMultipleFile({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/file/upload-multiple`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* removeFile({ payload: { key, callback } }) {
  const payload = {
    method: "delete",
    url: `file/remove?key=${key}`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* File() {
  yield all([takeLatest(UPLOAD_FILE, uploadFile)]);
  yield all([takeLatest(UPLOAD_MULTIPLE_FILE, uploadMultipleFile)]);
  yield all([takeLatest(REMOVE_FILE, removeFile)]);
}

export default File;
