import { createAction } from "redux-actions";

export const GET_ALL_PACKAGE= "GET_ALL_PACKAGE";
export const getAllPackage= createAction(GET_ALL_PACKAGE);


export const ADD_PACKAGE= "ADD_PACKAGE";
export const addPackage= createAction(ADD_PACKAGE);


export const UPDATE_PACKAGE= "UPDATE_PACKAGE";
export const updatePackage= createAction(UPDATE_PACKAGE);


export const DELETE_PACKAGE= "DELETE_PACKAGE";
export const deletePackage= createAction(DELETE_PACKAGE);



