import React, { useContext } from 'react';
import Header from '../Header';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import * as ROUTE from '../../../routes/routesConstant';
import { AuthContext } from '../../../contexts/AuthContext';
import Sidebar from '../Sidebar';

const PrivateLayout = (props) => {
  const auth = useContext(AuthContext);
  const { pathname } = useLocation();

  if (
    !auth &&
    pathname !== '/privacy-policy' &&
    pathname !== '/terms-and-service'
  ) {
    return <Navigate to={{ pathname: ROUTE.SIGN_IN }} />;
  }
  return (
    <React.Fragment>
      <Header role="" isAuth={auth} />
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <main
            role="main"
            className="main col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-5"
          >
            <Outlet />
          </main>
        </div>
      </div>
      <Sidebar />
    </React.Fragment>
  );
};

export default PrivateLayout;
