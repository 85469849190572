import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { connect } from "react-redux";
import {
  getAllGrade,
  deleteGrade,
  updateGrade,
  addGrade,
} from "../../stores/actions/grade.actions.types";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { errorMessage } from "../../utilities/notification";

const Grade = (props) => {
  const [gradeData, setGradeData] = useState();
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [selectId, setSelectId] = useState();

  useEffect(() => {
    getGradeList();
  }, []);

  const getGradeList = () => {
    const callback = (data) => {
      if (data.success) {
        setGradeData(data.data.data);
      }
    };
    props.getAllGrade({ data: {}, callback });
  };

  const deleteGrade = (id) => {
    const callback = (data) => {
      if (data.success) {
        getGradeList();
      }
    };
    props.deleteGrade({ data: id, callback });
  };

  const updateGrade = () => {
    if (!name) {
      errorMessage("Name is required");
      return;
    }
    const value = { id: selectId, name: name };
    const callback = (data) => {
      if (data.success) {
        setUpdateModalOpen(false);
        setName("");
        setSelectId();
        getGradeList();
      }
    };
    props.updateGrade({ data: value, callback });
  };

  const addGrade = () => {
    if (!name) {
      errorMessage("Name is required");
      return;
    }
    const value = { name: name };
    const callback = (data) => {
      if (data.success) {
        setUpdateModalOpen(false);
        setName("");
        setSelectId();
        getGradeList();
      }
    };
    props.addGrade({ data: value, callback });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Grade",
        Cell: ({ row }) => {
          return (
            <>
              <span>{row.original.name}</span>
            </>
          );
        },
      },
      {
        accessorKey: "createdAt",
        header: "Create",
        Cell: ({ row }) => {
          return (
            <>
              <span>{moment(row.original.createdAt).format("LLL")}</span>
            </>
          );
        },
      },
      {
        accessorKey: "updatedAt",
        header: "Update",
        Cell: ({ row }) => {
          return (
            <>
              <span>{moment(row.original.updatedAt).format("LLL")}</span>
            </>
          );
        },
      },
      {
        header: "Edit",
        Cell: ({ row }) => {
          return (
            <div>
              <button
                className="btn btn-primary btn-sm editIconBtn"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
                onClick={() => {
                  setName(row.original.name);
                  setSelectId(row.original._id);
                  setUpdateModalOpen(true);
                }}
              >
                <i className="fa fa-pencil-square"></i>
              </button>
            </div>
          );
        },
      },
      {
        header: "Remove",
        Cell: ({ row }) => {
          return (
            <div>
              <button
                onClick={() =>
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      deleteGrade(row.original._id);
                    }
                  })
                }
                className="btn btn-danger btn-sm editIconBtn"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
              >
                <i className="fas fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <Button
        onClick={() => {
          setName();
          setSelectId("");
          setUpdateModalOpen(true);
        }}
        className="mb-3"
      >
        Add Grade
      </Button>
      {gradeData ? (
        <MaterialReactTable
          columns={columns}
          data={gradeData}
          muiTableHeadCellProps={{
            align: "center",
          }}
          muiTableBodyCellProps={{
            align: "center",
          }}
          // enablePinning
          defaultColumn={{
            maxSize: 100,
            minSize: 100,
          }}
          enableColumnResizing
          columnResizeMode="onChange" //default
        />
      ) : null}

      <Modal
        show={updateModalOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          onClick={() => {
            setName();
            setSelectId("");
            setUpdateModalOpen(false);
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            {selectId ? "Update Grade" : "Add Grade"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className="form-control  mb-2 mx-2"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter name"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setUpdateModalOpen(false);
            }}
          >
            Close
          </Button>
          <Button onClick={() => (selectId ? updateGrade() : addGrade())}>
            {selectId ? "Update" : "Add"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  visible: state.app.visible,
});
export default connect(mapStateToProps, {
  getAllGrade,
  deleteGrade,
  updateGrade,
  addGrade,
})(Grade);
