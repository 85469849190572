import { all } from "redux-saga/effects";
import user from "./user";
import Subject from "./subject";
import Class from "./class";
import Grade from "./grade";
import Dashboard from "./dashboard";
import Package from "./package";
import Test from "./test";
import File from "./file";

const sagas = function* sagas() {
  yield all([
    user(),
    Subject(),
    Class(),
    Grade(),
    Dashboard(),
    Package(),
    Test(),
    File(),
  ]);
};

export default sagas;
