import React, { lazy } from 'react';
import * as ROUTE from './routesConstant';
import { Routes, Route } from 'react-router-dom';
import PublicLayout from '../components/Layout/PublicLayout';
import PrivateLayout from '../components/Layout/PrivateLayout';
import Class from '../pages/Class/Class';
import User from '../pages/User/User';
import Grade from '../pages/Grade/Grade';
import Test from '../pages/Test/Test';
import AddTest from '../pages/Test/AddTest';
import UpdateText from '../pages/Test/UpdateText';
import GetReview from '../pages/GetReview/GetReview';
import GetMark from '../pages/GetMark/GetMark';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndService from '../pages/TermsAndService/TermsAndService';
const SignIn = lazy(() => import('../pages/SignIn/SignIn'));
const Pagenotfound = lazy(() => import('../pages/PageNotFound/PageNotFound'));
const Package = lazy(() => import('../pages/Package/Package'));
const Subject = lazy(() => import('../pages/Subject/Subject'));

const Router = () => {
  return (
    <React.Suspense fallback={'Loading...'}>
      <Routes>
        <Route element={<PublicLayout />}>
          <Route path={ROUTE.SIGN_IN} element={<SignIn />} />
          <Route path="*" element={<Pagenotfound />} />
          <Route path={ROUTE.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={ROUTE.TERMS_AND_SERVICE} element={<TermsAndService />} />
        </Route>
        <Route element={<PrivateLayout />}>
          <Route path={ROUTE.USER} element={<User />} />
          <Route path={ROUTE.PACKAGE} element={<Package />} />
          <Route path={ROUTE.SUBJECT} element={<Subject />} />
          <Route path={ROUTE.CLASS} element={<Class />} />
          <Route path={ROUTE.GRADE} element={<Grade />} />
          <Route path={ROUTE.TEST} element={<Test />} />
          <Route path={ROUTE.ADDTEST} element={<AddTest />} />
          <Route path={ROUTE.GETREVIEW} element={<GetReview />} />
          <Route path={ROUTE.GETMARK} element={<GetMark />} />

          <Route path={`${ROUTE.UPDATETEST}/:id`} element={<UpdateText />} />

          <Route path="*" element={<Pagenotfound />} />
        </Route>
      </Routes>
    </React.Suspense>
  );
};

export default Router;
