import { all, takeLatest } from "redux-saga/effects";
import {
  GET_ALL_PACKAGE,
  ADD_PACKAGE,
  UPDATE_PACKAGE,
  DELETE_PACKAGE,
} from "../actions/package.actions.types";
import httpClient from "../services/http.client";
import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* getAllPackage({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "get",
    url: `/admin/getAllPackage`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* addPackage({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/test/addPackage`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* updatePackage({ payload: { data, callback } }) {
  const payload = {
    data: data.formData,
    method: "patch",
    url: `/test/updatePackage/${data.id}`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* deletePackage({ payload: { data, callback } }) {
  const payload = {
    method: "delete",
    url: `/admin/deletePackage/${data}`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* Package() {
  yield all([
    takeLatest(GET_ALL_PACKAGE, getAllPackage),
    takeLatest(ADD_PACKAGE, addPackage),
    takeLatest(UPDATE_PACKAGE, updatePackage),
    takeLatest(DELETE_PACKAGE, deletePackage),
  ]);
}

export default Package;
