import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import App from './pages/App/App';
import reportWebVitals from './reportWebVitals';
import AuthProvider from './contexts/AuthContext';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import configureStore from './stores';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

const { store } = configureStore();
let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
