import { all, takeLatest } from 'redux-saga/effects';
import {
  GET_ALL_TEST,
  ADD_TEST,
  UPDATE_TEST,
  DELETE_TEST,
  GET_SINGLE_TEST,
  GET_MARK_LIST,
  UPDATE_MARK_REVIEW,
  GET_REVIEW_LIST,
  UPDATE_REVIEW,
} from '../actions/test.actions.types';
import httpClient from '../services/http.client';
import * as Effects from 'redux-saga/effects';
const call = Effects.call;

function* getAllTest({ payload: { data, callback } }) {
  const payload = {
    data,
    method: 'get',
    url: `/admin/getAllTest`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}
function* getSingleTest({ payload: { data, callback } }) {
  const payload = {
    data,
    method: 'get',
    url: `/admin/getTest/${data}`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* addTest({ payload: { data, callback } }) {
  const payload = {
    data,
    method: 'post',
    url: `/test/addTest`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* updateTest({ payload: { data, callback } }) {
  const payload = {
    data,
    method: 'patch',
    url: `/test/updateTest/${data.id}`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* deleteTest({ payload: { data, callback } }) {
  const payload = {
    method: 'delete',
    url: `/admin/deleteTest/${data}`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* getMarkList({ payload: { data, callback } }) {
  const payload = {
    data,
    method: 'get',
    url: `/test/get-mark`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* updateMarkReview({ payload: { data, callback } }) {
  const payload = {
    data,
    method: 'post',
    url: `/test/updateMarkReview`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* getReviewList({ payload: { data, callback } }) {
  const payload = {
    data,
    method: 'get',
    url: `/test/get-review`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* updateReview({ payload: { data, callback } }) {
  const payload = {
    data: data.formData,
    method: 'patch',
    url: `/test/updateUserTest/${data.id}`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* Test() {
  yield all([
    takeLatest(GET_ALL_TEST, getAllTest),
    takeLatest(UPDATE_TEST, updateTest),
    takeLatest(DELETE_TEST, deleteTest),
    takeLatest(ADD_TEST, addTest),
    takeLatest(GET_SINGLE_TEST, getSingleTest),
    takeLatest(GET_MARK_LIST, getMarkList),
    takeLatest(UPDATE_MARK_REVIEW, updateMarkReview),
    takeLatest(GET_REVIEW_LIST, getReviewList),
    takeLatest(UPDATE_REVIEW, updateReview),
  ]);
}

export default Test;
