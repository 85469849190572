import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { getAllUser, deleteUser } from "../../stores/actions/dashboard.actions.types";
import moment from "moment-timezone";

const User = (props) => {
  const [userData, setUserData] = useState();

  useEffect(() => {
    getUserList();
  }, []);

  const getUserList = () => {
    const callback = (data) => {
      if (data.success) {
        setUserData(data.data.data);
      }
    };
    props.getAllUser({ data: {isDelete:true}, callback });
  };

  const deleteUser = (id) => {
    const callback = (data) => {
      if (data.success) {
        getUserList()
      }
    }
    props.deleteUser({ data: id, callback });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        Cell: ({ row }) => {
          return (
            <>
              <span>{row.original.name}</span>
            </>
          );
        },
      },
      {
        accessorKey: "email",
        header: "E-mail",
        Cell: ({ row }) => {
          return (
            <>
              <span>{row.original.email}</span>
            </>
          );
        },
      },
      {
        accessorKey: "createdAt",
        header: "Create",
        Cell: ({ row }) => {
          return (
            <>
              <span>{moment(row.original.createdAt).format("LLL")}</span>
            </>
          );
        },
      },
      {
        header: "Remove",
        Cell: ({ row }) => {
          return (
            <div>
              <button
                onClick={()=>Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                  if(result.isConfirmed){
                    deleteUser(row.original._id)
                  }
                })}
                className="btn btn-danger btn-sm editIconBtn"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
              >
                <i className="fas fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <>
      {userData ? (
        <MaterialReactTable
          columns={columns}
          data={userData}
          muiTableHeadCellProps={{
            align: "center",
          }}
          muiTableBodyCellProps={{
            align: "center",
          }}
          // enablePinning
          defaultColumn={{
            maxSize: 100,
            minSize: 100,
          }}
          enableColumnResizing
          columnResizeMode="onChange" //default
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  visible: state.app.visible,
});
export default connect(mapStateToProps, {
  getAllUser, deleteUser
})(User);
