import { createAction } from "redux-actions";

export const GET_ALL_CLASS = "GET_ALL_CLASS";
export const getAllClass = createAction(GET_ALL_CLASS);

export const ADD_CLASS = "ADD_CLASS";
export const addClass = createAction(ADD_CLASS);

export const UPDATE_CLASS = "UPDATE_CLASS";
export const updateClass = createAction(UPDATE_CLASS);

export const DELETE_CLASS = "DELETE_CLASS";
export const deleteClass = createAction(DELETE_CLASS);
