import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userslice } from "../../stores/slices/user";

const Header = (props) => {
  if (props.isAuth) {
    return <AuthHeader />;
  } else {
    return <NonAuthHeader />;
  }
};

const AuthHeader = () => {
  const navigate = useNavigate();

  //let location = useLocation();
  const dispatch = useDispatch();
  const [isToggle, toggle] = useState(false);
  const toggleMenu = () => toggle(!isToggle);
  /*useEffect(() => {
    toggle((isToggle) => !isToggle);
  }, [location]);*/

  return (
    <nav className="navbar navbar-expand-md fixed-top navbar-dark navtop">
      <div className="container">
        <Link className="navbar-brand" to="/">
          Resono
        </Link>
        <button
          onClick={toggleMenu}
          className="navbar-toggler p-0 border-0"
          type="button"
          data-toggle="offcanvas"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`navbar-collapse offcanvas-collapse ${
            isToggle ? "open" : ""
          }`}
          id="navbarsExampleDefault"
        >
          <ul style={{ marginLeft: "auto !important" }} className="navbar-nav">
            <li className="nav-item">
              <Link
                onClick={(e) => {
                  dispatch(userslice.actions.logoutAction());
                  navigate("./");
                }}
                className="nav-link"
                to="/"
              >
                Signout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const NonAuthHeader = () => {
  /*let location = useLocation();*/
  const [isToggle, toggle] = useState(false);
  const toggleMenu = () => toggle(!isToggle);

  /*useEffect(() => {
    toggle((isToggle) => !isToggle);
  }, [location]);*/

  return (
    <nav className="navbar navbar-expand-md fixed-top navbar-dark navtop">
      <div className="container">
        <Link className="navbar-brand" to="/">
          Resono
        </Link>
        <button
          onClick={toggleMenu}
          className="navbar-toggler p-0 border-0"
          type="button"
          data-toggle="offcanvas"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`navbar-collapse offcanvas-collapse ${
            isToggle ? "open" : ""
          }`}
          id="navbarsExampleDefault"
        ></div>
      </div>
    </nav>
  );
};

export default Header;
