import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const locationPath = useLocation();

  return (
    <nav
      id="sidebarMenu"
      className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
    >
      <div className="position-sticky pt-3">
        <ul className="nav flex-column">
          <Link to="/dashboard" className="nav-item">
            <a
              className={`nav-link ${
                locationPath.pathname === '/dashboard' ? 'active' : ''
              }`}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                stroke={
                  locationPath.pathname === '/dashboard' ? '#000000' : '#bcbcbc'
                }
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>{' '}
              Users
            </a>
          </Link>
          <Link to="/package" className="nav-item">
            <a
              className={`nav-link ${
                locationPath.pathname === '/package' ? 'active' : ''
              }`}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                stroke={
                  locationPath.pathname === '/package' ? '#000000' : '#bcbcbc'
                }
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M6 2L3 6v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V6l-3-4H6zM3.8 6h16.4M16 10a4 4 0 1 1-8 0" />
              </svg>{' '}
              Package
            </a>
          </Link>
          <Link to="/subject" className="nav-item">
            <a
              className={`nav-link ${
                locationPath.pathname === '/subject' ? 'active' : ''
              }`}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                stroke={
                  locationPath.pathname === '/subject' ? '#000000' : '#bcbcbc'
                }
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
                <path d="M14 3v5h5M16 13H8M16 17H8M10 9H8" />
              </svg>{' '}
              Subject
            </a>
          </Link>
          {/* <Link to="/class" className="nav-item">
            <a
              className={`nav-link ${
                locationPath.pathname === "/class" ? "active" : ""
              }`}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                stroke={
                  locationPath.pathname === "/class" ? "#000000" : "#bcbcbc"
                }
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M20 9v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9" />
                <path d="M9 22V12h6v10M2 10.6L12 2l10 8.6" />
              </svg>{" "}
              Class
            </a>
          </Link> */}

          <Link to="/grade" className="nav-item">
            <a
              className={`nav-link ${
                locationPath.pathname === '/grade' ? 'active' : ''
              }`}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                stroke={
                  locationPath.pathname === '/grade' ? '#000000' : '#bcbcbc'
                }
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M3 3v18h18" />
                <path d="M18.7 8l-5.1 5.2-2.8-2.7L7 14.3" />
              </svg>{' '}
              Grade
            </a>
          </Link>
          <Link to="/test" className="nav-item">
            <a
              className={`nav-link ${
                locationPath.pathname === '/test' ? 'active' : ''
              }`}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                stroke={
                  locationPath.pathname === '/test' ? '#000000' : '#bcbcbc'
                }
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
              </svg>{' '}
              Test
            </a>
          </Link>
          <Link to="/get-review" className="nav-item">
            <a
              className={`nav-link ${
                locationPath.pathname === '/get-review' ? 'active' : ''
              }`}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                stroke={
                  locationPath.pathname === '/get-review'
                    ? '#000000'
                    : '#bcbcbc'
                }
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M6 2L3 6v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V6l-3-4H6zM3.8 6h16.4M16 10a4 4 0 1 1-8 0" />
              </svg>{' '}
              Get Review
            </a>
          </Link>
          <Link to="/get-mark" className="nav-item">
            <a
              className={`nav-link ${
                locationPath.pathname === '/get-mark' ? 'active' : ''
              }`}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                stroke={
                  locationPath.pathname === '/get-mark' ? '#000000' : '#bcbcbc'
                }
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M6 2L3 6v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V6l-3-4H6zM3.8 6h16.4M16 10a4 4 0 1 1-8 0" />
              </svg>{' '}
              Get Mark
            </a>
          </Link>
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
