import { createAction } from "redux-actions";

export const GET_ALL_TEST = "GET_ALL_TEST";
export const getAllTest = createAction(GET_ALL_TEST);

export const ADD_TEST = "ADD_TEST";
export const addTest = createAction(ADD_TEST);

export const UPDATE_TEST = "UPDATE_TEST";
export const updateTest = createAction(UPDATE_TEST);

export const GET_SINGLE_TEST = "GET_SINGLE_TEST";
export const getSingleTest = createAction(GET_SINGLE_TEST);

export const DELETE_TEST = "DELETE_TEST";
export const deleteTest = createAction(DELETE_TEST);

export const GET_MARK_LIST = "GET_MARK_LIST";
export const getMarkList = createAction(GET_MARK_LIST);

export const UPDATE_MARK_REVIEW = "UPDATE_MARK_REVIEW";
export const updateMarkReview = createAction(UPDATE_MARK_REVIEW);

export const GET_REVIEW_LIST = "GET_REVIEW_LIST";
export const getReviewList = createAction(GET_REVIEW_LIST);

export const UPDATE_REVIEW = "UPDATE_REVIEW";
export const updateReview = createAction(UPDATE_REVIEW);
