import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { connect } from "react-redux";
import {
  getAllTest,
  deleteTest,
} from "../../stores/actions/test.actions.types";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import "react-form-builder2/dist/app.css";
import { useNavigate } from "react-router-dom";
import * as ROUTE from "../../routes/routesConstant";

const Test = (props) => {
  const [testData, setTestData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getTestList();
  }, []);

  const getTestList = () => {
    const callback = (data) => {
      if (data.success) {
        setTestData(data.data.data);
      }
    };
    props.getAllTest({ data: {}, callback });
  };

  const deleteTest = (id) => {
    const callback = (data) => {
      if (data.success) {
        getTestList();
      }
    };
    props.deleteTest({ data: id, callback });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Test",
        Cell: ({ row }) => {
          return (
            <>
              <span>{row.original.name}</span>
            </>
          );
        },
      },
      {
        accessorKey: "totalQuestion",
        header: "Total Question",
        Cell: ({ row }) => {
          return (
            <>
              <span>{row.original.totalQuestion}</span>
            </>
          );
        },
      },
      {
        accessorKey: "totalTime",
        header: "Total Time",
        Cell: ({ row }) => {
          return (
            <>
              <span>{row.original.totalTime}</span>
            </>
          );
        },
      },
      {
        accessorKey: "updatedAt",
        header: "Update",
        Cell: ({ row }) => {
          return (
            <>
              <span>{moment(row.original.updatedAt).format("LLL")}</span>
            </>
          );
        },
      },
      {
        header: "Edit",
        Cell: ({ row }) => {
          return (
            // <Link to={`${ROUTE.UPDATETEST}/${row.original.id}`}>
            <div>
              <button
                className="btn btn-primary btn-sm editIconBtn"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
                onClick={() => {
                  if (row.original._id) {
                    navigate(`${ROUTE.UPDATETEST}/${row.original._id}`);
                  }
                }}
              >
                <i className="fa fa-pencil-square"></i>
              </button>
            </div>
            // </Link>
          );
        },
      },
      {
        header: "Remove",
        Cell: ({ row }) => {
          return (
            <div>
              <button
                onClick={() =>
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      deleteTest(row.original._id);
                    }
                  })
                }
                className="btn btn-danger btn-sm editIconBtn"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
              >
                <i className="fas fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <Button
        className="mb-3"
        onClick={() => {
          navigate("/add-test");
        }}
      >
        Add Test
      </Button>

      {testData ? (
        <MaterialReactTable
          columns={columns}
          data={testData}
          muiTableHeadCellProps={{
            align: "center",
          }}
          muiTableBodyCellProps={{
            align: "center",
          }}
          // enablePinning
          defaultColumn={{
            maxSize: 100,
            minSize: 100,
          }}
          enableColumnResizing
          columnResizeMode="onChange" //default
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  visible: state.app.visible,
});
export default connect(mapStateToProps, {
  getAllTest,
  deleteTest,
})(Test);
