import { all, takeLatest } from "redux-saga/effects";
import {
  GET_ALL_GRADE,
  ADD_GRADE,
  UPDATE_GRADE,
  DELETE_GRADE,
} from "../actions/grade.actions.types";
import httpClient from "../services/http.client";
import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* getAllGrade({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "get",
    url: `/admin/getAllGrade`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* addGrade({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/admin/addGrade`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* updateGrade({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "patch",
    url: `/admin/updateGrade/${data.id}`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* deleteGrade({ payload: { data, callback } }) {
  const payload = {
    method: "delete",
    url: `/admin/deleteGrade/${data}`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* Grade() {
  yield all([
    takeLatest(GET_ALL_GRADE, getAllGrade),
    takeLatest(ADD_GRADE, addGrade),
    takeLatest(UPDATE_GRADE, updateGrade),
    takeLatest(DELETE_GRADE, deleteGrade),
  ]);
}

export default Grade;
