const TermsAndService = () => {
  return (
    <div className="c4 doc-content" style={{ marginTop: '10px' }}>
      <p>
        ПОЛИТИКА ЗА СИГУРНОСТ И ЗАЩИТА НА ЛИЧНИТЕ ДАННИ НА ФИЗИЧЕСКИ ЛИЦА
        ОСНОВНИ ПРИНЦИПИ И ИНФОРМАЦИЯ :Политиката за сигурност и защита на
        личните данни третира въпросите, свързани с поверителността на личните
        данни на физически лица, предоставени доброволно на „Домашна академия“
        ООД, наричано и само търговец/дружество/администратор на лични данни.
        Използването на услугите на  образователната платформа за интерактивно
        обучение RESONO.ME, администрирана от „Домашна академия“ ООД, достъпът
        до която се осигурява чрез интернет сайта https://resono.me//“сайта“/ и
        мобилното приложение Resono/“приложението“/ става единствено след
        приемане на настоящата политика за поверителност в нейната цялост. 
        Данни за „Домашна академия“ ООД като администратор на лични данни:ЕИК:
        206220101,  седалище и адрес на управление: Три Уши 103, гр. София,
        България, e-mail: office@resono.me Данни за контакт с длъжностното лице
        по защита на данните: Живко Георгиев Грачанлиев,
        имейл: zh.grachanliev@gmail.com  При обработването на личните данни
        администраторът на лични данни спазва всички приложими съобразно
        дейността му нормативни актове.  Относимата нормативна уредба включва,
        но не се ограничава, до Общия регламент за защита на личните данни
        (Регламент (ЕС) 2016/679), Закона за защита на личните данни, както и
        свързаните с тях oтносими актове, част от националното законодателство и
        правото на ЕС. Настоящата Политика има за цел да презентира кратка и
        съдържателна информация за физическите лица, които предоставят  свои
        лични данни или данни на трети лица на „Домашна академия“ ООД.  Всяко
        лице, което е предоставило или възнамерява по някакъв повод да
        предостави лични данни на дружеството има право  да се запознае с
        настоящата политика за сигурност и защита. I. ДЕФИНИТИВНИ РАЗПОРЕДБИ
        „Лични данни” са всяка информация, отнасяща се до физическо лице, което
        е идентифицирано или може да бъде идентифицирано пряко или непряко чрез
        идентификатор като име, идентификационен номер, данни за
        местонахождение, онлайн идентификатор или чрез един или повече признаци,
        специфични за физическата, физиологичната, генетичната, психическата,
        умствената, икономическата, културната или социална идентичност на това
        физическо лице „Обработване на лични данни“ е всяко действие или
        съвкупност от действия, които могат да се извършват по отношение на
        личните данни с автоматични или други средства, като събиране,
        записване, организиране, съхраняване, адаптиране или изменение,
        възстановяване, консултиране, употреба, разкриване чрез предаване,
        разпространяване, предоставяне, актуализиране или комбиниране,
        блокиране, заличаване или унищожаване. „Администратор“ е всяко физическо
        или юридическо лице, публичен орган, агенция или друга структура, която
        сама или съвместно с други определя целите и средствата за обработването
        на лични данни; когато целите и средствата за това обработване се
        определят от правото на ЕС или правото на държава членка,
        администраторът или специалните критерии за неговото определяне могат да
        бъдат установени в правото на Съюза или в правото на държава членка;
        „Субект на данните“ е всяко физическо лице, чиито лични данни се
        съхраняват от Администратора. „Съгласие на субекта на данните“ е всяко
        свободно изразено, конкретно, информирано и недвусмислено указание за
        волята на субекта на данните, посредством изявление или ясно
        потвърждаващо действие, което изразява съгласието му свързаните с него
        лични данни да бъдат обработени;  „Трета страна“– всяко физическо или
        юридическо лице, публичен орган, агенция или друг орган, различен от
        субекта на данните, администратора, обработващия лични данни и лицата,
        които под прякото ръководство на администратора или на обработващия
        лични данни имат право да обработват личните данни; II. КОИ ЛИЧНИ ДАННИ
        СА ОБЕКТ НА ОБРАБОТКА ОТ АДМИНИСТРАТОРА 1. При осъществяване на своята
        търговска дейност дружеството сключва и изпълнява договори с предмет,
        предоставянето на услуги, описани в общите условия за ползване на онлайн
        образователната платформа за интерактивно обучение RESONO, достъпа до
        която се осигурява чрез интернет сайта https://resono.me//“сайта“/ и
        мобилното приложение Resono/“приложението“/, в процеса на който, събира
        и обработва лични данни от асоциираните с конкретната дейност
        потребители:  физически лица, адресирали към дружеството искане за
        ползване на  предлаганите чрез сайта/приложението услуги; искания за
        консултация чрез обаждане по телефон и/или електронна поща,  съобщение в
        социалните мрежи или по друг начин във връзка с предлаганите от
        търговеца услуги; В зависимост от конкретното действие, дружеството
        събира следните данни: при регистрация за ползване на платформата: име,
        имейл адрес, телефонен номер, парола за достъп до профила в платформата,
        информация относно учебния клас и учебно направление, и друга
        информация, съгласно общите условия за регистрация и ползване на
        платформата. при регистрация за ползване на платформата чрез профили в
        Google или други социални мрежа, дружеството събира данни от съответния
        профил като те могат да включват имейл, имена, снимка, възраст,
        професионална информация и други лични данни. Търговецът събира тези
        данни само при положение, че потребителят сам е предоставил разрешение
        на съответната социална мрежа за събирането и съхранението им. при
        осъществен достъп до профила на съответния потребител, сървърите на
        дружеството автоматично записват  информация за IP адреса, дали
        влизането е през мобилна версия, приложение или десктоп браузър, както и
        информация дали влизането е с профил от някоя социална мрежа (social
        login). при извършвате плащания за ползването на предлаганите от
        платформата услуги, в зависимост от избрания начин на плащане, е
        възможно лични данни на потребителя да бъдат предадени на трети лица. За
        тази цел дружеството използва легитимни методи на разплащане чрез
        доставчици на платежни услуги, които спазват високи стандарти за защита
        на личните данни. 2. „Домашна академия“ ООД използва и бисквитки както и
        други технологии за „проследяване“, които събират информация от
        потребителя при взаимодействие със сайта/приложението, за да се подобри
        тяхната функционалност и да се оптимизира и улесни ползването им. Какво
        са бисквитки? Бисквитките са малки текстови файлове, които уебсайтът
        може да запише на вашия компютър или мобилно устройство, когато
        посещавате страница или сайт. Бисквитката ще помогне на сайта или на
        други сайтове да разпознаят устройство на конкретния потребител
        следващия път, когато го посети. Web beacons или други подобни файлове
        могат да правят същото. Терминът „бисквитки“ в тази политика се
        използва, за да се назовават всички файлове, които събират информация по
        този или сходен начин. Бисквитките изпълняват множество различни
        функции. Например, те помагат  на „Домашна академия“ ООД да запомни
        потребителското име или предпочитания на клиентите, да анализират
        функционалността и представянето на сайта/приложението, да се показват
        реклами или интернет базирана информация, релевантни на интересите на
        съответния потребител. 3. В хода на изпълнение на своята работа
        дружеството може да получи и събере и други лични данни, касаещи както
        потребителя, така и трети лица, вкл. информация за банкова сметка, и др.
        информация, която би могла да се окачестви като лична и строго
        поверителна. Събирането на тази информация не е самоцелна, а само и
        единствено в контекста на предлаганите чрез платформата услуги и
        доколкото е необходима. 4. За събирането и обработката на личните данни
        администраторът изисква изричното съгласие на потребителя, което може да
        бъде оттеглено по всяко време.  III. ОСНОВНИ ЦЕЛИ НА ОБРАБОТКА НА
        ЛИЧНИТЕ ДАННИ  1. Търговецът събира и обработва лични данни в изпълнение
        на своята дейност, като основанията и целите за обработка на лични данни
        могат да се систематизират в следните направления: а/  за регистрация и
        управление на профила на потребителя в платформата; б/  за ползването на
        услуги, предлагани от платформата, включително тези, изискващи плащания;
        в/  за изпращането на електронни съобщения за предлаганите от
        платформата услуги, ако потребителят изрично е дал съгласието си за
        това; г/  за спазване на определени задължения, произтичащи от
        императивни нормативни задължения при осъществяването на търговската
        дейност на администратора; 2. Лични данни може да се използват за други
        цели от дружеството само след изрично писмено съгласие на субекта на
        лични данни или ако има правно основание за това. IV. СЪХРАНЕНИЕ И
        ДОСТЪП ДО ЛИЧНИТЕ ДАННИ 1. Всички физически лица, чиито данни се събират
        или обработват от администратора имат право на достъп до тях. При
        съхранението на данни, дружеството прилага основополагащия принцип за
        съхранение на данни в обема, необходим за  извършване на конкретна
        дейност и за срок не по-дълъг от необходимото време за постигане на
        конкретните цели. След постигане им обработката на личните данни те се
        унищожават.  2. Всеки потребител на платформата има следните права: да
        знае какви лични данни обработва дружеството, за какво ги използва и как
        ги съхранява;  да поиска: а/ да бъдат коригирани предоставените лични
        данни; б/ да бъдат изтрити някои или всички лични данни; в/ да бъде
        ограничено обработването на личните  данни за определен период от време;
        г/ да възрази срещу обработването на определени данни и преносимостта на
        данните към трети лица; 3. Някои от изброените права могат да бъдат да
        бъдат ограничени, ако администраторът е задължен по нормативен ред да
        съхрани личните данни или това е необходимо в защита на негов легитимен
        правен интерес. 4. Данните, обработвани въз основа на съгласие, се
        съхраняват до неговото оттегляне. Ако съгласието се оттегли, личните
        данни се изтриват незабавно, освен ако дружеството няма друго правно
        основание за тяхната обработка съгласно приложимото законодателство или
        в защитата на свой легитимен правен интерес. 5. В случаите, когато
        оттеглянето по точка 4 и/или искането за заличаване и/или ограничаване
        по т. 2 е по отношение на лични данни, необходими за поддържане и
        администриране на профила на потребителя, същият се деактивира/изтрива
        автоматично. V. СПОДЕЛЯНЕ НА ДАННИ С ТРЕТИ ЛИЦА Дружеството споделя
        лични данни само в следните случаи: 1/ при извършвани от легитимни
        държавни органи, институции и/или длъжностни лица, проверки, на които
        администраторът е  длъжeн да предоставя лични данни по силата на закон;
        2/ на търговски партньори, с които дружеството работи въз основа на
        договор и чрез които се обезпечава осъществяването на дейността на
        платформата и с които търговецът има споразумения за запазване на
        конфиденциалността на информацията и защита на личните данни.
        Администраторът взима мерки всички тези лица да прилагат същата защита
        към лични данни, както неговите служители и да се съобразяват с
        политиката на търговеца за защита на личните данни. За „Домашна
        академия“ ООД е приоритет да ползва услугите само на такива контрагенти,
        които се отнасят отговорно към защитата на личните данни и прилагат
        подходящи технически и организационни мерки за тяхното съхранение.  VI.
        СЪХРАНЕНИЕ НА ЛИЧНИ ДАННИ. СРОК. 1. Домашна Академия ООД предприема
        необходимите технически и организационни мерки за сигурност с цел защита
        на администрираните лични данни срещу неразрешено и незаконно
        обработване, случайна загуба, унищожаване или увреждане на лични данни,
        каквито може да се изискват, като се има предвид общото състояние на
        технологичното ниво на развитие, обичайната практика и законовите
        изисквания. 2. За да защити личните данни от злоупотреба от трети
        страни, информацията подадена от потребителите е в криптирана форма
        посредством SSL (Secure Socket Layer) протокол. Всеки потребител може да
        се увери в това по наличието на зелено с „катинарче“ в адрес бара на
        ползвания браузър, и по това, че URL адресът на уеб сайта започва с
        „https“. 3. Съгласно принципите, свързани с обработването на лични
        данни, прогласени в Регламент (ЕС) 2016/679 – Общ регламент относно
        защитата на данните, и по-конкретно в съответствие с чл. 5, § 1, б. „д”,
        личните данни могат законосъобразно да се съхраняват във форма, която да
        позволява идентифицирането на субекта на данни за период не по-дълъг от
        необходимото за целите, за които се обработват личните данни  – принцип
        въвеждащ „ограничение на съхранението” на данните. 4.  Данните на
        потребителите, ползващи услугите на платформата се съхраняват за срока,
        определен в действащите закони, когато е приложимо, или за срок в
        зависимост от целта, за която са събрани. Когато личните данни, които се
        събират, вече не са необходими за упоменатите цели,  те се заличават.
        5.  Данните, които са предоставени при регистрация на  профила се пазят
        за срок до 5 години след деактивиране/изтриване на профила, освен ако
        при деактивацията субектът не изрази изричното си желание същите да
        бъдат заличени незабавно. Целта на съхраняването на личните данни е да
        се изискват минимални действия от страна на потребителя при
        пререгистрации или повторно предоставяне на данни.  6.  Правото на
        субекта по т. 5 може да бъде ограничено, ако администраторът е задължен
        по нормативен ред или в защита на свой легитимен правен интерес да
        съхрани личните  данни, за които се иска заличаване. VII. ПРАВО НА
        ЗАЩИТА НА СУБЕКТИТЕ 1. В случай, че сметнете, че вашите права като
        субект на лични данни са нарушени, може да се свържете с длъжностното
        лице по защита на данните на „Домашна академия“ ООД на имейл
        zh.grachanliev@gmail.com; тел. 0892330745  2. Всеки има право да подаде
        и ЖАЛБА срещу администратор и/или обработващ лични данни за нарушение на
        права по Регламент (ЕС) 2016/679 и/или Закона за защита на личните данни
        или  СИГНАЛ  за нарушение на законодателството, когато нe са засегнати
        конкретни права. 3. Жалби и сигнали могат да бъда подадени до следния
        компетентен административен орган:  Наименование: Комисия за защита на
        личните данни Седалище и адрес на управление: гр. София 1592, бул. Проф.
        Цветан Лазаров №2 Адрес за кореспонденция: гр. София 1592, бул. Проф.
        Цветан Лазаров №2 Телефон: 02 915 3 518 Интернет страница: www.cpdp.bg
        VIII.АктуализацияНастоящите политики за сигурност по отношение на
        личните данни  се актуализират периодично с цел максимална яснота,
        прецизност и съответствие приложимите нормативни изисквания, съобразно
        националното законодателство и правото на ЕС.  Дата на последна
        актуализация - 20.03.2023
      </p>
    </div>
  );
};

export default TermsAndService;
