import { all, takeLatest } from "redux-saga/effects";
import {
  GET_ALL_SUBJECT,
  ADD_SUBJECT,
  UPDATE_SUBJECT,
  DELETE_SUBJECT,
} from "../actions/subject.actions.types";
import httpClient from "../services/http.client";
import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* getAllSubject({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "get",
    url: `/admin/getAllSubject`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* addSubject({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/admin/addSubject`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* updateSubject({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "patch",
    url: `/admin/updateSubject/${data.id}`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* deleteSubject({ payload: { data, callback } }) {
  const payload = {
    method: "delete",
    url: `/admin/deleteSubject/${data}`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* Subject() {
  yield all([
    takeLatest(GET_ALL_SUBJECT, getAllSubject),
    takeLatest(ADD_SUBJECT, addSubject),
    takeLatest(UPDATE_SUBJECT, updateSubject),
    takeLatest(DELETE_SUBJECT, deleteSubject),
  ]);
}

export default Subject;
