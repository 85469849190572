const PrivacyPolicy = () => {
  return (
    <div className="c4 doc-content" style={{ marginTop: '10px' }}>
      <p className="c2">
        <span>
          GENERAL TERMS OF USE ONLINE EDUCATIONAL PLATFORM RESONO.ME' "
          Preamble:" " These general conditions /'Terms'/ are an important part
          of the relationship between the entity providing the services and the
          users using the online educational platform for interactive learning
          RESONO.ME /'the platform'/ access to which is provided through the
          website{' '}
        </span>
        <span className="c1">
          <a
            className="c0"
            href="https://www.google.com/url?q=https://resono.me&amp;sa=D&amp;source=editors&amp;ust=1693474083011205&amp;usg=AOvVaw3INxCb3U0eD5ggEr44JylW"
          >
            https://resono.me
          </a>
        </span>
        <span>
          &nbsp;/ 'the site'/ and the Resono mobile application/'the
          application'/ Therefore, it is extremely important for both you and us
          to read and understand these general conditions, because they contain
          the main parameters and characteristics of the legal relationship,
          arising from the requested/used services, including the limitations
          and exclusions of the responsibility of the provider of the relevant
          service and the user's rights." "By accessing (loading) the website{' '}
        </span>
        <span className="c1">
          <a
            className="c0"
            href="https://www.google.com/url?q=https://resono.me&amp;sa=D&amp;source=editors&amp;ust=1693474083011546&amp;usg=AOvVaw2ibvuSxudTyZyJCDJEGg8j"
          >
            https://resono.me
          </a>
        </span>
        <span>
          &nbsp;or launching the Resono application, the user agrees to be bound
          by these General Terms and Conditions and any subsequent changes to
          them, and undertakes to comply with them." " II. Definitions within
          the meaning of these general conditions, general provisions:"
          "'Supplier/merchant' is the legal entity, commercial company,
          contractor of the service used/requested, through the site or the
          application, by the respective user;" "Service is any of the
          personalized services offered by the 'Provider/Merchant' to the
          Customer/User." "Site is the website{' '}
        </span>
        <span className="c1">
          <a
            className="c0"
            href="https://www.google.com/url?q=https://resono.me&amp;sa=D&amp;source=editors&amp;ust=1693474083011910&amp;usg=AOvVaw3IOnaNW6Fc9LVkTweiT2_9"
          >
            https://resono.me
          </a>
        </span>
        <span className="c3">
          &nbsp;" "Application is a Resono mobile application" "User/client is
          any user of the site who has applied for/uses the services offered
          through the site/application. A client/user is considered to be any
          natural person with legal capacity or a legal entity registered
          according to the relevant statutory order of the relevant
          jurisdiction;" "Order is an individualized personal request for the
          use of the relevant paid service by a customer/user. The order
          represents an invitation from the customer to enter into a contractual
          relationship for the delivery of a service. In order for there to be a
          binding legal relationship between the customer and the supplier, it
          is necessary for the order to be confirmed by the merchant according
          to the order provided in the present general terms and conditions."
          "Confirmation is the express declaration of intent by which the
          provider of the relevant service confirms to the customer that the
          order made by him has been received and accepted. After receiving
          confirmation of an order, it can be refused by the customer only after
          the express consent of the provider, except in the cases when the
          right of refusal and cancellation of the customer's contract derives
          directly from the law or is made according to these general
          conditions;" "'Delivery/Performance of a Service' is the entire
          process or essential step of the performance of the service requested
          by the customer." " III. General information about the merchant:"
          "Service Provider:" "'Domashna Akademiya' OOD, headquarters and
          management address: 103 Tri Ushi Street, Sofia, Bulgaria, contact
          person: Ekaterina Grachanlieva, email: etangalova@gmail.com, phone:
          0892330745" "IV. General characteristics and requirements for the
          services provided:" "The services provided by the educational
          interactive platform RESONO.ME include:" " Services without charge:"
          "free access to informational educational resources/materials (in the
          form of tests, tasks, questions) provided through the web browser or
          mobile application for students of IV, VII, X and XII grades with
          educational direction: Bulgarian language and literature and
          mathematics;" " an interactive connection providing the possibility of
          immediate, momentary verification and assessment of the result
          achieved by the user/user;" "Paid Services:" "personalized access to
          informational educational resources/materials (in the form of tests,
          tasks, questions) provided through the web browser or mobile
          application for students of IV, VII, X and XII grades with educational
          direction: Bulgarian language and literature and mathematics;" " an
          interactive connection providing the possibility of individual
          verification and assessment of the result achieved by the user/user,
          carried out by a person (teacher) with the relevant professional and
          educational competence. The term for the performance of this service
          is 3 working days, counted from the date of making the payment; " "V.
          Intellectual Property Rights" " 1. All intellectual property rights
          regarding the educational information resources/materials published on
          the site/application are subject to protection within the meaning of
          the current Copyright Law and its related rights, and their
          unregulated use constitutes an illegal act and leads to civil ,
          administrative and criminal liability in accordance with the current
          Bulgarian legislation and the law of the European Union." " 2. Users
          have no right to copy, reproduce, modify, distribute, share or use in
          any other way the content provided to them through the web browser or
          mobile application and informational educational resources/materials.
          They are intended for individual use and by providing them, the
          provider does not cede to the user any exclusive or non-exclusive
          intellectual property rights." " VI. Access to the Platform" "The user
          alone and at his own expense should provide the necessary technical
          resources (computer equipment, mobile device, Internet connectivity,
          appropriate software, etc.) that he needs to access the site or the
          application." " 1. The registration of a user profile providing access
          to the platform through the site or the application is free of charge.
          The creation of the user profile requires acceptance of the present
          general terms and conditions as well as the provision of information
          necessary to customize the profile data providing access to specific
          content of the platform: name, email address, access password, study
          class and field of study. After registration, user profile data can be
          changed." " 2. The registration of a user profile of a minor is
          carried out by his parents/guardians." " 3. Minors create their user
          profile with the consent of parents/guardians." " 4. In the user
          profile settings, users undertake to provide true, truthful and
          accurate information, complying with the requirements of these General
          Terms and Conditions." " 5. A natural person has the right to register
          one profile for use of the platform. The user with a registered
          profile has no right to provide other persons with the data and
          information providing access to the platform through the profile
          registered by him." " 6. In case the provider finds that a profile is
          used by more than one person, it has the right, without warning and
          unconditionally, to suspend access to the platform by
          deactivating/deleting the profile used by more than one person." " 7.
          Account deactivation/deletion can be done by the user at any time." "
          8. The Provider reserves the right to temporarily restrict access to
          the platform for the purpose of software maintenance, updates and
          debugging." " VII. General rules for access to the services supported
          by the platform" " 1. The provider guarantees that the information
          educational resources/materials offered are up-to-date and in
          accordance with the established state educational standards for the
          relevant class and subject." " 2. With registration, each user gets
          free access to the content and services supported by the platform, for
          which no additional payment is required." " 3. Each user has the
          opportunity to request access and place an order for each of the paid
          services offered through the site and the application." " 4. Detailed
          information about the characteristics of each of the services offered
          - including price and time of execution, when applicable, is published
          and visualized on the site and the application." " 5. Access to the
          services offered by the merchant, related to the provision of
          educational information resources/materials (in the form of tests,
          tasks, questions) for students of IV, VII, X, and XII grades with
          educational direction: Bulgarian language and literature and math is
          done from the Store section of each registered user's profile
          interface." " 6. The merchant provides the services available through
          the platform only as digital content by means of digital media." "VII.
          Access to Paid Services" " 1. Registered users receive personalized
          profile access to services determined by the merchant after payment of
          the relevant fee. Detailed information on the price, type, content and
          term of performance of each specific paid service is contained in the
          'Shop' section, accessible from the profile interface of any
          registered user." " 2. The specified fees payable by the user for the
          use of a specific service are in BGN, with VAT included." " 3. The
          user declares the use of a certain paid service through the virtual
          button activate." " 4. Before finally confirming his order, by
          pressing the virtual button indicating that he assumes the obligation
          to pay, the customer should confirm:" "that he has been notified that
          the execution of the order requires payment on his part;" "that he
          agrees that the execution of the order should begin and be carried out
          immediately, starting from the moment of payment and before the
          expiration of the legally established 14-day period for withdrawing
          from the contract;" "that he is aware that he loses his statutory
          right to withdraw from the contract within 14 days from the moment the
          service is performed;" "that he is familiar with the general
          conditions, agrees with their content and undertakes to abide by them
          unconditionally;" " 5. By performing these actions, the customer
          invites the provider to provide the services chosen by him." " 6. For
          the order made by the user and the payment made, the supplier sends an
          electronic message to confirmation of the email address specified in
          the data provided by the customer during his profile registration. The
          confirmation contains information about the characteristics of the
          order placed, the price paid and other parameters and/or conditions
          related to the specifics of the order." "VIII. Prices and method of
          payment" " 1 The price of the service requested by the customer,
          including all due fees, taxes, payments to third parties owed by the
          supplier." " 2. Payment of the price for the requested service is
          carried out in the following way:" " Through a virtual POS terminal;"
          " 3. The provider reserves the right to update the prices of the
          services offered." " IX. Conclusion of Contract." " 1. The information
          contained on the site and the application regarding the offered paid
          services constitutes an invitation to enter into a contract with the
          users of the platform." " 2. The customer declares to the supplier a
          customized order according to his interest by sending it to the
          merchant according to the present conditions." " 3. The contractual
          relationship in connection with a service requested through the site
          or the application between the customer and the supplier is considered
          to have arisen with confirmation by the merchant in accordance with
          item 6, section VII, except in cases where it does not follow from
          these general conditions something else." "X. Protection of personal
          data" "The provider processes and stores personal data of customers in
          accordance with Regulation (EU) 2016/679 of the European Parliament
          and of the Council on the protection of individuals with regard to the
          processing of personal data and on the free movement of such data and
          on the repeal of Directive 95/ 46/EC ('General Data Protection
          Regulation') Information on how the same can be exercised and any
          other information that the General Data Protection Regulation requires
          to be provided to customers is contained in the policy for the
          protection of personal data of the provider. By declaring his
          agreement with these general conditions, the customer declares that he
          is informed about the protection of his personal data." "XI. Exclusion
          of Supplier's Liability:" "The service provider is not responsible for
          damages caused to the customer in cases of:" "Incorrectly, incorrectly
          or in bad faith data entered by the user;" " Damages resulting from
          circumstances beyond the control of the provider, such as the list is
          not exhaustive: in case of force majeure, accidental event, force
          majeure, problems related to access and connection to the Internet,
          problems due to the customer's equipment, as well as in case of
          unregulated access or intervention of third parties in the functioning
          of the information system or the provider's servers;" "Damage caused
          to Customer's software or hardware or for loss of data in connection
          with use of the Website or Application;" "Damage as a result of using
          someone else's personal data contrary to these general terms and
          conditions, as well as for inaccurate or falsely provided data and
          information;" "Temporary restriction of access to the platform for
          software corrections and prevention." "XII. Term of validity" " 1.
          These General Terms and Conditions apply to users from the moment of
          profile registration providing access to the platform, until the
          moment of deactivation/deletion of the profile." " 2. By
          deactivating/deleting the profile, the user loses access to the paid
          services supported by the platform, which access cannot be restored
          upon repeated profile registration of the same person." " XIII. FINAL
          CLAUSES. APPLICABLE LAW" " 1. The provider reserves the right at any
          time to change these general terms and conditions in accordance with
          legal requirements. Any change will be announced on the site and
          through the application. " " 2. The user is considered notified and
          bound by the updates made and published." " 3. If one or more of the
          provisions of these general conditions are invalid, illegal or
          unenforceable, this does not affect the validity of the remaining
          provisions." " 4. These general terms and conditions are governed by
          Bulgarian and EU law. For all unresolved issues in these general terms
          and conditions, the relevant Bulgarian and European legislation shall
          apply.
        </span>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
