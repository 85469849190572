import { createAction } from "redux-actions";

export const GET_ALL_SUBJECT = "GET_ALL_SUBJECT";
export const getAllSubject = createAction(GET_ALL_SUBJECT);

export const ADD_SUBJECT = "ADD_SUBJECT";
export const addSubject = createAction(ADD_SUBJECT);


export const UPDATE_SUBJECT = "UPDATE_SUBJECT";
export const updateSubject = createAction(UPDATE_SUBJECT);


export const DELETE_SUBJECT = "DELETE_SUBJECT";
export const deleteSubject = createAction(DELETE_SUBJECT);

